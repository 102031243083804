import React, { Component } from 'react';
import "./styles/ribbon.scss";
import logo from "../resources/icons/logo-green.svg";

class Ribbon extends Component {
    state = {  } 
    render() { 
        return ( 
        <div className="ribbon-holder">
            <div style={{backgroundImage: `url(${logo})`}}  className="ribbon">
            </div>
        </div> );
    }
}
 
export default Ribbon;

