import React, { Component, lazy, Suspense } from 'react'
import { unmountComponentAtNode, render } from "react-dom";
// import Nav from './nav';
// import Highlight from './highlight';
// import Content from './content';
// import Footer from './footer';
import "./styles/container.scss";
import AgeGate from './ageGate';
import Loader from './loader';
// import Aware from './awareCode';

const Nav = lazy(() => import('./nav'));
const Highlight = lazy(() => import('./highlight'));
const Content = lazy(() => import('./content'));
const Footer = lazy(() => import('./footer'));
const Aware = lazy(() => import('./awareCode'));

class Container extends Component {
    // state = { focus:0, shouldHighlight: false, age:false, shoppable: "" }

    constructor(props) {
        super(props);
        this.state = {
            focus: 0,
            shouldHighlight: false,
            age: false,
            shoppable: "",
            closable: "",
            wanda: false
        }

        if (this.getCookie()) {
            console.log("Already passed age gate");
            this.state.age = true;
        }



    }

    componentDidMount() {
        window.addEventListener("scroll", this.animateThings);
        setTimeout(() => {
            document.querySelector(".loader-holder").style.display = "none";
          }, 3500);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.focus !== this.state.focus) {
            // console.log("Theres been a change in focus from " + prevState.focus + " to " + this.state.focus);
        }
    }

    ammendFocus = (_section) => {
        var focussed = -1;
        switch (_section) {
            case -1:
                focussed = 0;
                // console.log("Okay were reverting bck to focus 0 because section is: " + _section)
                break;
            case 0:
                focussed = 1;
                break;
            case 1:
                focussed = 6;
                break;
            case 2:
                focussed = 7;
                break;
            default:
                break;
        }
        // console.log("WE'RE CHANING FOCUS TO " + focussed + " BECAUSE SECTION WAS " + _section)
        this.setState({ focus: focussed });
        if (this.state.focus === 0 && focussed !== 0) {
            // console.log("Some fuckery is about")
            this.setState({ focus: focussed });
        }
    }

    ammendFocusProd = (_prod) => {
        var prod = -1;
        switch (_prod) {
            case 0:
                prod = 2;
                break;
            case 1:
                prod = 3;
                break;
            case 2:
                prod = 4;
                break;
            case 3:
                prod = 5;
                break;
            default:
                break;
        }
        this.setState({ focus: prod });
    }

    highlight = (_should) => {
        // console.log("SHOULD WE HIGHLIGHT RIGHT NOW? " + _should);
        this.setState({ shouldHighlight: _should }, () => {
            if (_should == false) {
                this.ammendFocus(-1)
            }
        });
    }

    enableClosable = () => {
        this.setState({ closable: "enable" })
    }

    disableClosable = () => {
        this.setState({ closable: "" })
    }

    enter = () => {
        this.setState({ age: true });
    }

    fullscreen = () => {
        this.setState({ shoppable: "hide" });
    }

    unfullscreen = () => {
        this.setState({ shoppable: "" });
    }

    getCookie() {
        var name = "ageGate";
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    // isInViewport =(element)=> {
    //     const rect = element.getBoundingClientRect();
    //     return (
    //         rect.top >= 0 &&
    //         rect.left >= 0 &&
    //         rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    //         rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    //     );
    // }

    animateThings() {
        // Recycle animated
        document.querySelector("#recycle").style.transform = "rotate(" + window.pageYOffset / 2 + "deg)";

        document.querySelector("#recycleBurn").style.transform = "rotate(" + window.pageYOffset / 5 + "deg)";
        // Wanda logo animated
        document.querySelector("#wanda-logo").style.transform = "rotate(" + (window.pageYOffset / 4) * -1 + "deg)";

        document.querySelector("#logo-p1").style.transform = "rotate(" + (window.pageYOffset / 10) * -1 + "deg)";

        document.querySelector("#circle").style.transform = "rotate(" + (window.pageYOffset / 10) * -1 + "deg)";

        var mat1 = document.querySelector(".shotone");
        var mat2 = document.querySelector(".shottwo");
        var mat3 = document.querySelector(".shotthree");
        // var mat4 = document.querySelector(".shotfour");
        // var mat5 = document.querySelector(".shotfive");
        // var mat6 = document.querySelector(".shotsix");
        // var mat7 = document.querySelector(".shotseven");
        // var mat8 = document.querySelector(".shoteight");
        var av1 = document.querySelector("#avatar1");
        var av2= document.querySelector("#avatar2");
        var av3 = document.querySelector("#avatar3");

        if (mat1.getBoundingClientRect().top < 500) {
            if (!mat1.classList.contains("show")) {
                mat1.classList.add("show")
            }
        }
        else {
            if (mat1.classList.contains("show")) {
                mat1.classList.remove("show")
            }
        }

        if (mat2.getBoundingClientRect().top < 400) {
            if (!mat2.classList.contains("show")) {
                mat2.classList.add("show")
            }
        }
        else {
            if (mat2.classList.contains("show")) {
                mat2.classList.remove("show")
            }
        }

        if (mat3.getBoundingClientRect().top < 300) {
            if (!mat3.classList.contains("show")) {
                mat3.classList.add("show")
            }
        }
        else {
            if (mat3.classList.contains("show")) {
                mat3.classList.remove("show")
            }
        }

        // if (mat4.getBoundingClientRect().top < 200) {
        //     if (!mat4.classList.contains("show")) {
        //         mat4.classList.add("show")
        //     }
        // }
        // else {
        //     if (mat4.classList.contains("show")) {
        //         mat4.classList.remove("show")
        //     }
        // }

        // if (mat5.getBoundingClientRect().top < 200) {
        //     if (!mat5.classList.contains("show")) {
        //         mat5.classList.add("show")
        //     }
        // }
        // else {
        //     if (mat5.classList.contains("show")) {
        //         mat5.classList.remove("show")
        //     }
        // }

        // if (mat6.getBoundingClientRect().top < 200) {
        //     if (!mat6.classList.contains("show")) {
        //         mat6.classList.add("show")
        //     }
        // }
        // else {
        //     if (mat6.classList.contains("show")) {
        //         mat6.classList.remove("show")
        //     }
        // }

        // if (mat7.getBoundingClientRect().top < 200) {
        //     if (!mat7.classList.contains("show")) {
        //         mat7.classList.add("show")
        //     }
        // }
        // else {
        //     if (mat7.classList.contains("show")) {
        //         mat7.classList.remove("show")
        //     }
        // }

        // if (mat8.getBoundingClientRect().top < 200) {
        //     if (!mat8.classList.contains("show")) {
        //         mat8.classList.add("show")
        //     }
        // }
        // else {
        //     if (mat8.classList.contains("show")) {
        //         mat8.classList.remove("show")
        //     }
        // }

        if (av1.getBoundingClientRect().top < 600) {
            if (!av1.classList.contains("show")) {
                av1.classList.add("show")
            }
        }
        else {
            if (av1.classList.contains("show")) {
                av1.classList.remove("show")
            }
        }

        if (av2.getBoundingClientRect().top < 500) {
            if (!av2.classList.contains("show")) {
                av2.classList.add("show")
            }
        }
        else {
            if (av2.classList.contains("show")) {
                av2.classList.remove("show")
            }
        }

        if (av3.getBoundingClientRect().top < 400) {
            if (!av3.classList.contains("show")) {
                av3.classList.add("show")
            }
        }
        else {
            if (av3.classList.contains("show")) {
                av3.classList.remove("show")
            }
        }

 


        // this.checkShow(mat1, 500);
        // this.checkShow(mat2, 400);
        // this.checkShow(mat3, 300);
        // var status = this.isInViewport(document.querySelector(".material"));
        // if(status){
        //     console.log("I see you")
        // }
    }

    checkShow(material, height) {
        if (material.getBoundingClientRect().top < height) {
            if (!material.classList.contains("show")) {
                material.classList.add("show")
            }
        }
        else {
            if (material.classList.contains("show")) {
                material.classList.remove("show")
            }
        }
    }

    showWanda=()=>{
        this.setState({wanda: true});
    }

    hideWanda=()=>{
        this.setState({wanda: false});
    }

    render() {
        const age = this.state.age;
        return (
            <div className="website-holder">
                {age ?
                    <div id="site-section" className="site">
                        <Loader/>
                        <Suspense fallback={Loader}>
                            <Nav closable={this.state.closable} close={this.disableClosable} open={this.enableClosable} shoppable={this.state.shoppable} />
                            <div id="content">
                                <Highlight wanda={this.state.wanda} hideWanda={this.hideWanda} section={this.state.focus} updateHighlight={this.state.shouldHighlight} fullscreen={this.fullscreen} unfullscreen={this.unfullscreen} />
                                <Content showWanda={this.showWanda} showCLTB={this.state.closable} enableCLTB={this.enableClosable} ammendFocus={this.ammendFocus} ammendFocusProd={this.ammendFocusProd} updateHighlight={this.highlight} />
                            </div>
                            <Footer />
                            <Aware />
                        </Suspense>
                    </div>
                    : <AgeGate enter={this.enter} />}
            </div>);
    }
}

export default Container;



{/* <div className="website-holder">
                {age ?
                    <div id="site-section" className="site">
                        <Suspense>
                            <Nav closable={this.state.closable} close={this.disableClosable} open={this.enableClosable} shoppable={this.state.shoppable} />
                            <div id="content">
                                <Highlight section={this.state.focus} updateHighlight={this.state.shouldHighlight} fullscreen={this.fullscreen} unfullscreen={this.unfullscreen} />
                                <Content showCLTB={this.state.closable} enableCLTB={this.enableClosable} ammendFocus={this.ammendFocus} ammendFocusProd={this.ammendFocusProd} updateHighlight={this.highlight} />
                            </div>
                            <Footer />
                            <Aware />
                        </Suspense>
                    </div>
                    : <AgeGate enter={this.enter} />}
            </div>); */}