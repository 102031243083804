import React, { Component } from 'react'
import Ribbon from './ribbon';
import Aware from './awareCode';
import logo from "../resources/icons/dry-goods-green.svg"
import BarLoader from "react-spinners/BarLoader";
import "./styles/loader.scss";

class Loader extends Component {
    state = { show: "showing" } 

    componentDidMount(){
        document.querySelector("body").style.overflow = "hidden";
        setTimeout(() => {
            this.setState({show: "hide"})
            document.querySelector("body").style.overflow = "scroll";
          }, 3000);

          setTimeout(() => {
            // this.setState({show: "hide"})
            document.querySelector(".loader-holder").style.display = "none";
          }, 3500);
    }

    render() {
        return (
            <div className={`loader-holder ${this.state.show}`} style={{backgroundColor: "white"}}>
                <div className="logo-holder">
                    <img alt="Savanna X Wanda Lephoto logo" srcset={logo} />
                </div>
                <div className="inner-content">
                    <Ribbon />
                    <div className="loading-section">
                        <h2>DRIP INCOMING</h2>
                        <BarLoader color="#5F6064" width={500} />
                    </div>
                </div>
                <div className='content-highlight-row' id="bottom-cotent">
                    <div className="section one"><p>WATERPROOF<br />APPAREL<br />FOR</p></div>
                    <div className="section two"><p></p></div>
                    <div className="section three"><p>A<br />WORLD<br />UNDERWATER</p></div>
                </div>
                <div id='aware-holder'>
                    <Aware />
                </div>
            </div>);
    }
}
 
export default Loader;