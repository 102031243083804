import React from 'react';
import "./styles/awareCode.scss";
import ARA from "../resources/no_under.webp";
import ARAText from "../resources/ara-text.png";

function Aware() {
    return ( 
    <div className="ara-holder">
        <img id='araImg' srcSet={ARA} alt="" />
        <img id='araText' srcSet={ARAText} alt="" />
    </div> );
}

export default Aware;