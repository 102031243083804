import React, { Component } from 'react'
import Ribbon from './ribbon';
import Aware from './awareCode';
import logo from "../resources/icons/dry-goods-logo.svg"
import bg from "../resources/age-gate-bg.webp"
import "./styles/ageGate.scss";

class AgeGate extends Component {
    state = { country: "" }

    componentDidMount() {
        fetch("https://ipapi.co/json/")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ country: result.country_code_iso3 });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidUpdate(prevState) {
        if (prevState.country !== this.state.country) {
            this.updateSelected();
        }
    }

    updateSelected() {
        console.log("Auto setting country");
        document.querySelector("#country").value = this.state.country;
    }

    getAge = (dateString) => {
        console.log(dateString);
        var today = new Date();
        var birthDate = new Date(dateString);
        console.log(birthDate);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        console.log(age);
        return age;
    }

    checkAge = (e) => {
        e.preventDefault()
        var day = document.querySelector("#form-day").value;
        var month = document.querySelector("#form-month").value;
        var year = document.querySelector("#form-year").value;
        var age = year + "/" + month + "/" + day;
        console.log(age);
        if (this.getAge(age) >= 18) {
            this.dropCookie()
            this.props.enter();
        }
    }

    dropCookie() {
        var now = new Date();
        now.setMonth(now.getMonth() + 1);
        //EXPIRES AFTER A MONTH
        document.cookie = "ageGate=true; expires=" + now.toUTCString() + ";";

    }

    checkDay() {
        if (document.querySelector("#form-day").value.length >= 2) {
            document.querySelector("#form-month").focus();
        }
    }

    checkMonth() {
        if (document.querySelector("#form-month").value.length >= 2) {
            document.querySelector("#form-year").focus();
        }
    }

    checkYear() {
        if (document.querySelector("#form-year").value.length >= 4) {
            document.querySelector("#submit-age").focus();
        }
    }




    render() {
        return (
            <div className="ageGate-holder" style={{ backgroundImage: ` url(${bg})` }}>
                <div className="logo-holder">
                    <img alt="Savanna X Wanda Lephoto logo" srcset={logo} />
                </div>
                <div className="inner-content">
                    <Ribbon />
                    <div className="ageGate-form">
                        <p className="disclaimer">
                            Welcome to Distell Group Holdings Ltd. This website uses cookies to run
                            the website in various ways, such as providing essential services, advertising, and analytics. If you accept this notice, you consent to us using all cookies, including non-essential cookies. If you reject this notice, you consent to us using essential cookies only. You can opt-out of non-essential cookies or get more information about managing cookies in our Cookie Policy.
                        </p>
                        <div className="form-holder">
                            <h4>VERIFY YOUR AGE</h4>
                            <form>
                                <select class="form-control" id="country">
                                    <option value="" disabled selected>Country</option>
                                    <option value="AFG">Afghanistan</option>
                                    <option value="ALB">Albania</option>
                                    <option value="DZA">Algeria</option>
                                    <option value="SOE">American Samoa</option>
                                    <option value="AND">Andorra</option>
                                    <option value="AGO">Angola</option>
                                    <option value="AIA">Anguilla</option>
                                    <option value="ATG">Antigua And Barbuda</option>
                                    <option value="ARG">Argentina</option>
                                    <option value="ARM">Armenia</option>
                                    <option value="ABW">Aruba</option>
                                    <option value="AUS">Australia</option>
                                    <option value="AUT">Austria</option>
                                    <option value="AZE">Azerbaijan</option>
                                    <option value="BHS">Bahamas</option>
                                    <option value="BHR">Bahrain</option>
                                    <option value="BGD">Bangladesh</option>
                                    <option value="BRB">Barbados</option>
                                    <option value="BLR">Belarus</option>
                                    <option value="BEL">Belgium</option>
                                    <option value="BLZ">Belize</option>
                                    <option value="BEN">Benin</option>
                                    <option value="BMU">Bermuda</option>
                                    <option value="BTN">Bhutan</option>
                                    <option value="BOL">Bolivia</option>
                                    <option value="BIH">Bosnia and Herzegovina</option>
                                    <option value="BWA">Botswana</option>
                                    <option value="BRA">Brazil</option>
                                    <option value="BRN">Brunei</option>
                                    <option value="BGR">Bulgaria</option>
                                    <option value="BFA">Burkina Faso</option>
                                    <option value="BDI">Burundi</option>
                                    <option value="KHM">Cambodia</option>
                                    <option value="CMR">Cameroon</option>
                                    <option value="CAN">Canada</option>
                                    <option value="CAI">Canary Islands</option>
                                    <option value="CPV">Cape Verde Islands</option>
                                    <option value="CYM">Cayman Islands</option>
                                    <option value="CAF">Central African Republic</option>
                                    <option value="TCD">Chad</option>
                                    <option value="CHL">Chile</option>
                                    <option value="CHN">China</option>
                                    <option value="COL">Colombia</option>
                                    <option value="COM">Comoros</option>
                                    <option value="COG">Congo</option>
                                    <option value="COK">Cook Islands</option>
                                    <option value="CRI">Costa Rica</option>
                                    <option value="CIV">Cote D'Ivoire</option>
                                    <option value="HRV">Croatia</option>
                                    <option value="CUB">Cuba</option>
                                    <option value="CYP">Cyprus</option>
                                    <option value="CZE">Czech Republic</option>
                                    <option value="DNK">Denmark</option>
                                    <option value="DJI">Djibouti</option>
                                    <option value="DMA">Dominica</option>
                                    <option value="DOM">Dominican Republic</option>
                                    <option value="ZAI">DRC</option>
                                    <option value="ECU">Ecuador</option>
                                    <option value="EGY">Egypt</option>
                                    <option value="SLV">El Salvador</option>
                                    <option value="GNQ">Equatorial Guinea</option>
                                    <option value="ERI">Eritrea</option>
                                    <option value="EST">Estonia</option>
                                    <option value="SWZ">Eswatini</option>
                                    <option value="ETH">Ethiopia</option>
                                    <option value="EUR">European Euro</option>
                                    <option value="FLK">Falkland Islands</option>
                                    <option value="FJI">Fiji</option>
                                    <option value="FIN">Finland</option>
                                    <option value="FRA">France</option>
                                    <option value="GUF">French Guiana</option>
                                    <option value="PYF">French Polynesia</option>
                                    <option value="GAB">Gabon</option>
                                    <option value="GMB">Gambia</option>
                                    <option value="GEO">Georgia</option>
                                    <option value="DEU">Germany</option>
                                    <option value="GHA">Ghana</option>
                                    <option value="GIB">Gibraltar</option>
                                    <option value="GRC">Greece</option>
                                    <option value="GRL">Greenland</option>
                                    <option value="GRD">Grenada</option>
                                    <option value="GLP">Guadeloupe</option>
                                    <option value="GUM">Guam</option>
                                    <option value="GTM">Guatemala</option>
                                    <option value="GSY">Guernsey</option>
                                    <option value="GIN">Guinea</option>
                                    <option value="GNB">Guinea-Bissau</option>
                                    <option value="GUY">Guyana</option>
                                    <option value="HTI">Haiti</option>
                                    <option value="HND">Honduras</option>
                                    <option value="HKG">Hong Kong</option>
                                    <option value="HUN">Hungary</option>
                                    <option value="ISL">Iceland</option>
                                    <option value="IND">India</option>
                                    <option value="IDN">Indonesia</option>
                                    <option value="IRN">Iran</option>
                                    <option value="IRQ">Iraq</option>
                                    <option value="IOM">Isle Of Man</option>
                                    <option value="ISR">Israel</option>
                                    <option value="ITA">Italy</option>
                                    <option value="JAM">Jamaica</option>
                                    <option value="JPN">Japan</option>
                                    <option value="JSY">Jersey</option>
                                    <option value="JOR">Jordan</option>
                                    <option value="KAZ">Kazakhstan</option>
                                    <option value="KEN">Kenya</option>
                                    <option value="KIR">Kiribati</option>
                                    <option value="PRK">Korea D P R (North)</option>
                                    <option value="KOR">Korea Rep Of (South)</option>
                                    <option value="KWT">Kuwait</option>
                                    <option value="KGZ">Kyrgyz Republic</option>
                                    <option value="LAO">Laos</option>
                                    <option value="LVA">Latvia</option>
                                    <option value="LBN">Lebanon</option>
                                    <option value="LSO">Lesotho</option>
                                    <option value="LBR">Liberia</option>
                                    <option value="LBY">Libya</option>
                                    <option value="LIE">Liechtenstein</option>
                                    <option value="LTU">Lithuania</option>
                                    <option value="LUX">Luxembourg</option>
                                    <option value="MAC">Macau</option>
                                    <option value="MDG">Madagascar</option>
                                    <option value="MWI">Malawi</option>
                                    <option value="MYS">Malaysia</option>
                                    <option value="MDV">Maldives</option>
                                    <option value="MLI">Mali</option>
                                    <option value="MLT">Malta</option>
                                    <option value="MHL">Marshall Islands</option>
                                    <option value="MTQ">Martinique</option>
                                    <option value="MRT">Mauritania</option>
                                    <option value="MUS">Mauritius</option>
                                    <option value="MYT">Mayotte</option>
                                    <option value="MEX">Mexico</option>
                                    <option value="FSM">Micronesia Federal States Of</option>
                                    <option value="MDA">Moldova</option>
                                    <option value="MCO">Monaco</option>
                                    <option value="MNG">Mongolia</option>
                                    <option value="MNT">Montenegro</option>
                                    <option value="MSR">Montserrat</option>
                                    <option value="MAR">Morocco</option>
                                    <option value="MOZ">Mozambique</option>
                                    <option value="MMR">Myanmar</option>
                                    <option value="NAM">Namibia</option>
                                    <option value="NRU">Nauru</option>
                                    <option value="NPL">Nepal</option>
                                    <option value="NLD">Netherlands</option>
                                    <option value="ANT">Netherlands Antilles</option>
                                    <option value="NCL">New Caledonia</option>
                                    <option value="NZL">New Zealand</option>
                                    <option value="NIC">Nicaragua</option>
                                    <option value="NER">Niger</option>
                                    <option value="NGA">Nigeria</option>
                                    <option value="NIU">Niue</option>
                                    <option value="MKD">North Macedonia</option>
                                    <option value="MNP">Northern Mariana Islands</option>
                                    <option value="NOR">Norway</option>
                                    <option value="OMN">Oman</option>
                                    <option value="PAK">Pakistan</option>
                                    <option value="PLW">Palau</option>
                                    <option value="PSE">Palestine</option>
                                    <option value="PAN">Panama</option>
                                    <option value="PNG">Papua New Guinea</option>
                                    <option value="PRY">Paraguay</option>
                                    <option value="PER">Peru</option>
                                    <option value="PHL">Philippines</option>
                                    <option value="PCN">Pitcairn Islands</option>
                                    <option value="POL">Poland</option>
                                    <option value="PRT">Portugal</option>
                                    <option value="PRI">Puerto Rico</option>
                                    <option value="QAT">Qatar</option>
                                    <option value="IRE">Republic Of Ireland</option>
                                    <option value="REU">Reunion</option>
                                    <option value="ROM">Romania</option>
                                    <option value="RUS">Russian Federation</option>
                                    <option value="RWA">Rwanda</option>
                                    <option value="STK">Saint Kitts and Nevis</option>
                                    <option value="STL">Saint Lucia</option>
                                    <option value="SPM">Saint Pierre and Miquelon</option>
                                    <option value="STV">Saint Vincent and the Grenadines</option>
                                    <option value="SOW">Samoa</option>
                                    <option value="SMR">San Marino</option>
                                    <option value="STP">Sao Tome and Principe</option>
                                    <option value="SAU">Saudi Arabia</option>
                                    <option value="SEN">Senegal</option>
                                    <option value="SER">Serbia</option>
                                    <option value="SYC">Seychelles</option>
                                    <option value="SLE">Sierra Leone</option>
                                    <option value="SGP">Singapore</option>
                                    <option value="SVK">Slovak Republic</option>
                                    <option value="SVN">Slovenia</option>
                                    <option value="SLB">Solomon Islands</option>
                                    <option value="SOM">Somalia</option>
                                    <option value="ZAF">South Africa</option>
                                    <option value="SGS">South Georgia</option>
                                    <option value="ESP">Spain</option>
                                    <option value="LKA">Sri Lanka</option>
                                    <option value="SHM">St Helena</option>
                                    <option value="SDN">Sudan</option>
                                    <option value="SUR">Suriname</option>
                                    <option value="SWE">Sweden</option>
                                    <option value="CHE">Switzerland</option>
                                    <option value="SYR">Syria</option>
                                    <option value="TWN">Taiwan, Province of China</option>
                                    <option value="TJK">Tajikistan</option>
                                    <option value="TZA">Tanzania</option>
                                    <option value="THA">Thailand</option>
                                    <option value="TGO">Togo</option>
                                    <option value="TON">Tonga</option>
                                    <option value="TTO">Trinidad and Tobago</option>
                                    <option value="TUN">Tunisia</option>
                                    <option value="TUR">Turkey</option>
                                    <option value="TKE">Turkey (In Europe)</option>
                                    <option value="TKM">Turkmenistan</option>
                                    <option value="TCA">Turks and Caicos Islands</option>
                                    <option value="TUV">Tuvalu</option>
                                    <option value="UGA">Uganda</option>
                                    <option value="UKR">Ukraine</option>
                                    <option value="ARE">United Arab Emirates</option>
                                    <option value="GBR">United Kingdom</option>
                                    <option value="USA">United States Of America</option>
                                    <option value="URY">Uruguay</option>
                                    <option value="USR">Ussr</option>
                                    <option value="UZB">Uzbekistan</option>
                                    <option value="VUT">Vanuatu</option>
                                    <option value="VCS">Vatican City State</option>
                                    <option value="VEN">Venezuela</option>
                                    <option value="VNM">Vietnam</option>
                                    <option value="VGB">Virgin Islands - British</option>
                                    <option value="VIR">Virgin Islands - U S</option>
                                    <option value="WLF">Wallis and Futuna</option>
                                    <option value="YEM">Yemen</option>
                                    <option value="YUG">Yugoslavia</option>
                                    <option value="ZMB">Zambia</option>
                                    <option value="ZWE">Zimbabwe</option>
                                </select>
                                <div className="field">
                                    <input type="text" className='date-input' name="day" id="form-day" maxLength="2" onKeyUp={() => this.checkDay()} required />
                                    <label htmlFor="form-day">DAY</label>
                                </div>
                                <div className="field">
                                    <input type="text" className='date-input' name="month" id="form-month" maxLength="2" onKeyUp={() => this.checkMonth()} required />
                                    <label htmlFor="form-month">MONTH</label>
                                </div>
                                <div className="field">
                                    <input type="text" className='date-input' name="year" id="form-year" maxLength="4" onKeyUp={() => this.checkYear()} required />
                                    <label htmlFor="form-year">YEAR</label>
                                </div>
                                {/* <div className="submit">ENTER</div> */}
                                <input id='submit-age' onClick={this.checkAge} type="submit" value="ENTER" />
                            </form>
                        </div>

                    </div>
                </div>
                <div className='content-highlight-row' id="bottom-cotent">
                    <div className="section one"><p>WATERPROOF<br />APPAREL<br />FOR</p></div>
                    <div className="section two"><p></p></div>
                    <div className="section three"><p>A<br />WORLD<br />UNDERWATER</p></div>
                </div>
                <div id='aware-holder'>
                    <Aware />
                </div>
            </div>);
    }
}

export default AgeGate;